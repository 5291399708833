import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { Layout } from '@src/components';
import * as api from '@src/utils/mmc-api/api';
import { updateMe } from '../../utils/mmc-api/api';
import Input from '../../components/UI/Input';
import Spinner from '../../components/UI/Spinner';

const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}`);

const isBrowser = typeof window !== 'undefined';

const FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE';
const formReducer = (state, action) => {
  if (action.type === FORM_INPUT_UPDATE) {
    const updatedValues = {
      ...state.inputValues,
      [action.input]: action.value,
    };
    const updatedValidities = {
      ...state.inputValidities,
      [action.input]: action.isValid,
    };
    let updatedFormIsValid = true;
    for (const key in updatedValidities) {
      updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
    }
    return {
      formIsValid: updatedFormIsValid,
      inputValidities: updatedValidities,
      inputValues: updatedValues,
    };
  }
  return state;
};

const Checkout = (props) => {
  const [content, setContent] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const user = useSelector((state) => state.user.data);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [userDetails, setUserDetails] = useState(false);
  let cart;
  const dispatch = useDispatch();

  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      fullname: user?.fullname,
      email: user?.email,
      address: user?.address,
      town: user?.town,
      postcode: user?.postcode,
    },
    inputValidities: {
      fullname: !!user?.fullname,
      email: !!user?.email,
      address: !!user?.address,
      town: !!user?.town,
      postcode: !!user?.postcode,
    },
    formIsValid:
      !!user?.fullname && !!user?.email && !!user?.address && !!user?.town && !!user?.postcode,
  });

  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidity) => {
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        value: inputValue,
        isValid: inputValidity,
        input: inputIdentifier,
      });
    },
    [dispatchFormState],
  );

  useEffect(() => {
    if (error) {
      Alert.alert('An error occured', error, [{ text: 'Ok' }]);
    }
  }, [error]);

  const compareUser = () => {
    let equal = true;
    if (formState.inputValues.fullname !== user.fullname) {

      equal = false;
    }
    if (formState.inputValues.address !== user.address) {
      equal = false;
    }
    if (formState.inputValues.town !== user.town) {
      equal = false;
    }
    if (formState.inputValues.postcode !== user.postcode) {
      equal = false;
    }
    return equal;
  };

  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    if (user && user?.email) {
      api.stripePay().then(setClientSecret);
      fetchFullCart();
      if (formState.formIsValid) {
        setUserDetails(true);
      }
    }
  }, [user]);

  async function fetchFullCart() {
    cart = await api.fullCart();
    setContent(cart?.content || []);
    setTotalPrice(cart?.totalPrice || 0);
  }

  const preSubmission = () => {
    if (!formState.formIsValid) {
      alert('Please make sure all your details are filled in.');
      return false
    }
    if (!compareUser()) {
      updateMe({
        fullname: formState.inputValues.fullname,
        address: formState.inputValues.address,
        town: formState.inputValues.town,
        postcode: formState.inputValues.postcode,
      })
    }
    return true;
  };

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Layout>
      <>
        <>
          <article className="text-gray-700 max-w-screen-md mx-auto p-1">
            <p className="text-xl text-center my-3">
              <span className="p-1 border-b font-bold">Total</span>
              <span className="p-1 border-b ">£{totalPrice}</span>
            </p>

            {!userDetails && (
              <div className="user-form-container">
                <div className="margin-b">
                  <span className="border-b font-bold">Billing Details</span>
                </div>

                <form>
                  <Input
                    id="fullname"
                    label="Full Name"
                    required
                    type="text"
                    autoCapitalize="words"
                    errorText="Please enter your full name."
                    onInputChange={inputChangeHandler}
                    initialValue={user ? user.fullname : ''}
                    initiallyValid={!!user?.fullname}
                    updatedValue={user ? user.fullname : ''}
                  />

                  <Input
                    id="address"
                    label="Address"
                    required
                    type="text"
                    errorText="Please enter your address."
                    onInputChange={inputChangeHandler}
                    initialValue={user ? user?.address : ''}
                    initiallyValid={!!user?.address}
                    updatedValue={user ? user?.address : ''}
                  />
                  <Input
                    id="town"
                    label="Town"
                    required
                    type="text"
                    errorText="Please enter your town."
                    onInputChange={inputChangeHandler}
                    initialValue={user ? user?.town : ''}
                    initiallyValid={!!user?.town}
                    updatedValue={user ? user?.town : ''}
                  />
                  <Input
                    id="postcode"
                    label="Postcode"
                    required
                    type="text"
                    errorText="Please enter your postcode."
                    onInputChange={inputChangeHandler}
                    initialValue={user ? user?.postcode : ''}
                    initiallyValid={!!user?.postcode}
                    updatedValue={user ? user?.postcode : ''}
                  />
                </form>
              </div>
            )}

            {clientSecret && isBrowser && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm preSubmission={preSubmission} />
              </Elements>
            )}
          </article>
        </>
      </>
    </Layout>
  );
};

export default Checkout;
